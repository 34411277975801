/*******************************
             Site
*******************************/

html,
body {
  height: 100%;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  min-width: 320px;
  font-family: $font-site;
  background-color: $bg-color;
  color: $gray-darkest;
  font-size: $font-size;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-site;
  font-size: $font-size;
}

/*******************************
             Headers
*******************************/

h1,
h2,
h3,
h4,
h5 {
  line-height: 120%;
  margin: 25px 0em 14px;
  font-weight: 600;
  padding: 0;
  font-weight: 600;
}

h1 {
  font-size: 28px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0;
}

/*******************************
             Text
*******************************/

p {
  margin: 0 0 14px;
  line-height: 1.38;
}
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

ul {
  li {
    line-height: 1.38;
  }
}

/*******************************
             Links
*******************************/

a {
  color: $link-color;
  text-decoration: none;
}
a:hover {
  color: darken($link-color, 10%);
  text-decoration: none;
}

/*******************************
             Stauses
*******************************/

.status-label {
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  font-size: $font-size-mini;
}

.status-open {
  color: $gray-darkest;
}

.status-planned {
  color: #6435c9;
}

.status-started {
  color: #2185d0;
}

.status-completed {
  color: #21ba45;
}

.status-declined {
  color: #db2828;
}

.status-duplicate {
  color: #d6a100;
}

/*******************************
             Others
*******************************/

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.center {
  text-align: center;
}

*:focus {
  outline: 0;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
  appearance: button;
}

.page {
  flex: 1 0 auto;
  margin-top: 10px;
  padding-bottom: 20px;
}

.page::after {
  content: "";
  clear: both;
  display: table;
}

noscript {
  margin-top: 20px;
}

code {
  font-family: $font-code;
}

body,
h1,
h2,
h3,
h4,
h5,
.ui,
input {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.info {
  font-size: 90%;
  font-weight: 400;
  color: $gray-darker;
}

.right {
  float: right;
  vertical-align: middle;
}

.center {
  text-align: center;
}

svg.icon {
  display: inline-block;
  opacity: 1;
  width: 1.18em;
  vertical-align: middle;
}

.clickable {
  cursor: pointer;
}

.subtitle {
  font-size: $font-size-mini;
  margin: 10px 0 5px 0;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: $gray-dark;
  display: block;
  &.active {
    cursor: pointer;
    &:hover {
      color: $main-color;
    }
    svg.icon {
      float: right;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.markdown-body {
  overflow-wrap: break-word;
  word-break: break-word;

  blockquote {
    padding: 0 8px;
    color: $gray-darkest;
    border-left: 4px solid $gray-light;
    margin: 5px 0;
  }

  ul,
  ol {
    padding-left: 30px;
    margin: 0 0 14px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
  }

  pre {
    overflow-x: scroll;
    padding: 16px;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
    code {
      display: inline;
      max-width: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      line-height: inherit;
      word-wrap: normal;
      background-color: transparent;
      border: 0;
    }
  }
  code {
    padding: 0.2em;
    font-size: 85%;
    margin: 0;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 3px;
  }
}

.ui.inline.dropdown {
  > .text {
    border-bottom: 1px dashed #9e9e9e;
  }
}

.ui.basic.red.label {
  width: 100%;
  color: #ef7878 !important;
  border-color: #ef7878 !important;

  &.below {
    border-left: none;
    border-right: none;
    border-top: none;
  }

  &.above {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
}

.failure-page {
  height: 60%;
  text-align: center;
  margin-top: 100px;
  max-width: 800px !important;
  p {
    font-size: 130%;
    line-height: 30px;
  }
  h1 {
    font-size: 180%;
    color: #3b4450;
  }
  img {
    max-height: 100px;
    max-width: 100px;
  }
  .l-browser-list {
    text-align: left;
    max-width: 500px;
    margin: 0 auto;
    a {
      display: block;
    }
    .browser-logo {
      margin-right: 10px;
      vertical-align: middle;
      width: 48px;
      height: 48px;
    }
    .browser-name {
      font-weight: bold;
    }
    .browser-download {
      float: right;
      line-height: 48px;
    }
  }
}

.legal-page {
  max-width: 800px !important;
  padding: 50px 0;
  h1 {
    margin-bottom: 30px;
  }
  p {
    line-height: 150%;
  }

  table {
    tr {
      th {
        padding: 5px;
        border-bottom: 1px solid $gray;
        background-color: $gray-lighter;
      }
      td {
        border-bottom: 1px solid $gray;
        padding: 5px;
      }
    }
  }
}

.c-divider {
  display: table;
  font-weight: 700;
  margin: 15px 0px;

  &::before,
  &::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
  }
  &::before {
    background-position: right 1em top 50%;
  }
  &::after {
    background-position: left 1em top 50%;
  }
}

.c-toast {
  border-radius: 5px !important;
}

@include media("sm") {
  .hidden-sm {
    display: none;
  }
}

@include media("md") {
  .hidden-md {
    display: none;
  }
}

@include media("lg") {
  .hidden-lg {
    display: none;
  }
}

@include media("xl") {
  .hidden-xl {
    display: none;
  }
}
